<template>
    <ListPage title="Logi"
              :data="data"
              :is-busy="isBusy"
              :filters="{}"
              :table-params="[
                  {label: '#', field: 'id', sortable: true, align:'right'},
                  {label: 'Czas', field: 'created_at', sortable: true, align:'right', filter: 'datetime'},
                  {label: 'Kategoria', field: 'log_category', sortable: false, align: 'center'},
                  {label: 'Krótki opis', field: 'log_short_message', sortable: true, align:'left'},
                  {label: 'Czas wykonania', field: 'log_execution_time', sortable: true, align: 'right'},
              ]"
              :load-action-name="TYPES.PUNTLOGS_A_LOAD"
              :rowClassHandler="getRowClassHandler"
    >
    </ListPage>
</template>
<script>
import ListPage from "@/pages/Common/ListPage";
import * as TYPES from '@/store/types';
import * as PRIVILEGES from '@/config/Privileges'

import {mapGetters} from 'vuex'
import Vue from "vue";
import TestDialog from "@/pages/Common/TestDialog";
export default {
    name: 'SystemUsersListPage',
    components: {ListPage},
    data() {
        return {
            TYPES,
            PRIVILEGES
        }
    },
    computed: {
        ...mapGetters({
            data: [TYPES.PUNTLOGS_G_GET_ITEMS],
            isBusy: [TYPES.PUNTLOGS_G_GET_IS_BUSY]
        })
    },
    methods: {
        onClose() {
            console.log('panel closed');
        },
        getRowClassHandler(item) {
            console.log('item', item);
            return {
                'table-danger': item.log_type === 'ERROR' || item.log_type === 'FATAL',
                'table-warning': item.log_type === 'WARNING',
                'table-info': item.log_type === 'INFO'};

        }
    }
}
</script>