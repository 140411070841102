var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ListPage',{attrs:{"title":"Logi","data":_vm.data,"is-busy":_vm.isBusy,"filters":{},"table-params":[
              {label: '#', field: 'id', sortable: true, align:'right'},
              {label: 'Czas', field: 'created_at', sortable: true, align:'right', filter: 'datetime'},
              {label: 'Kategoria', field: 'log_category', sortable: false, align: 'center'},
              {label: 'Krótki opis', field: 'log_short_message', sortable: true, align:'left'},
              {label: 'Czas wykonania', field: 'log_execution_time', sortable: true, align: 'right'},
          ],"load-action-name":_vm.TYPES.PUNTLOGS_A_LOAD,"rowClassHandler":_vm.getRowClassHandler}})
}
var staticRenderFns = []

export { render, staticRenderFns }